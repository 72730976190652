<template>
  <div class="info-card">
    <MyLink
      class="info-card__image"
      :name="pageName"
      :params="{
        [slugName]: preview.fields.url.ru,
      }"
    >
      <img
        v-if="preview.fields.img"
        :src="useNormalizeCtfUrl(preview.fields.img.ru.fields.file.ru.url)"
        :alt="preview.fields.img.ru.fields.title.ru"
        loading="lazy"
      />
      <img v-else src="/img/photo-plug.svg" alt="banner" loading="lazy" />
    </MyLink>
    <div class="info-card__content">
      <div class="info-card__header">
        <BlogLabel :label="preview.fields.category.ru" />
        <time
          v-if="preview.fields.created"
          :datetime="new Date(preview.fields.created.ru).toISOString()"
          class="info-card__data"
        >
          {{ getDate(preview.fields.created.ru) }}
        </time>
      </div>
      <div class="info-card__body">
        <MyLink
          class="info-card__title"
          :name="pageName"
          :params="{
            [slugName]: preview.fields.url.ru,
          }"
        >
          {{ getLocalizedName(preview.fields.name) }}
        </MyLink>
        <p v-if="preview.fields.shortDescrition" class="info-card__subtitle">
          {{ getLocalizedName(preview.fields.shortDescrition) }}
        </p>
      </div>
      <div class="info-card__footer">
        <MyLink
          class="go-to"
          :name="pageName"
          :params="{
            [slugName]: preview.fields.url.ru,
          }"
        >
          {{ _T("@Go") }}
        </MyLink>
      </div>
    </div>
  </div>
</template>

<script setup>
import BlogLabel from "~/modules/blog/components/BlogLabel.vue";
import { useNormalizeCtfUrl } from "~/uses/useNormalizeCtfUrl";

const props = defineProps({
  preview: { type: Object, required: true },
  pageName: { type: String, required: true },
  slugName: { type: String, required: true },
});

const getDate = (date) =>
  useState("blog card date" + date, () =>
    new Intl.DateTimeFormat("uk-UA", {}).format(Date.parse(date)),
  );
</script>

<style lang="scss" scoped>
.info-card {
  @extend %shadow;
  background-color: white;

  border-radius: 8px;

  overflow: hidden;

  &__image {
    height: 100%;

    @include flex-container(column, center);
    flex: 1 0 242px;

    & > img {
      display: flex;
      object-fit: cover;
      height: inherit;
    }
  }

  &__content {
    height: 100%;

    @include flex-container(column, flex-start, center);

    padding: 16px 8px;
    gap: 16px;

    @include bigMobile {
      gap: 32px;
    }
  }

  &__header {
    width: 100%;

    @include flex-container(row, space-between, center);

    gap: 8px;
  }

  &__data {
    @include font(16, 22);
    letter-spacing: 0.02em;
    color: #393d38;
  }

  &__body {
    @include flex-container(column, flex-start, center);
    flex: 1 1 auto;

    gap: 8px;
  }

  &__title {
    @include font(16, 30, 600);
    color: var(--color-grey-900);
    text-transform: uppercase;
    text-align: center;

    @include bigMobile {
      @include font(18, 22, 600);
      text-transform: none;
    }
  }

  &__subtitle {
    @include font(16, 22);
    color: var(--color-sky-darker);
    text-align: center;
    letter-spacing: 0.02em;

    @include bigMobile {
      display: none;
    }
  }
}
</style>
